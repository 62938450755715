<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage" />
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="录单时间">
                <a-range-picker @change="onDateChange2" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务来源">
                <a-select
                  showSearch
                  :filter-option="filterOption"
                  allowClear
                  mode="multiple"
                  placeholder="请选择业务来源"
                  style="width: 100%"
                  v-model="queryParam['source']"
                >
                  <a-select-option v-for="op in sourceOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务类型">
                <a-select
                  showSearch
                  :filter-option="filterOption"
                  allowClear
                  placeholder="请选择业务类型"
                  style="width: 100%"
                  v-model="queryParam.business_type"
                >
                  <a-select-option v-for="op in bizTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="报关单号">
                <a-input placeholder="根据报关单号进行搜索" v-model="queryParam.report_num" allowClear />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="货物状态">
                <a-select
                  showSearch
                  :filter-option="filterOption"
                  allowClear
                  placeholder="请选择货物状态"
                  style="width: 100%"
                  v-model="queryParam.commodity_type_list"
                  mode="multiple"
                >
                  <a-select-option v-for="op in commodityOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="计划号">
                <a-input placeholder="根据计划号进行搜索" v-model="queryParam.plan_id" allowClear />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="入库时间">
                <a-range-picker @change="onDateChange" v-model="defaultDate" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="录单人">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择录单人"
                  style="width: 100%"
                  :filter-option="filterOption"
                  v-model="queryParam.creator_id"
                >
                  <a-select-option v-for="op in userOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="费用状态">
                <a-select
                  allowClear
                  placeholder="请选择费用状态"
                  style="width: 100%"
                  v-model="queryParam.fee_status_list"
                  mode="multiple"
                >
                  <a-select-option v-for="op in feeStatusList" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="品名">
                <a-input placeholder="根据品名进行搜索" v-model="queryParam.total_name" allowClear />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="报关单位">
                <a-input placeholder="根据报关单位进行搜索" v-model="queryParam.customs_name" allowClear />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="下发状态">
                <a-select allowClear placeholder="请选择下发状态" style="width: 100%" v-model="queryParam.store_status">
                  <a-select-option v-for="op in storeStatusOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="出库日期">
                <a-range-picker @change="onDateChange3" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="现金收款日期">
                <a-range-picker @change="onDateChange4" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="现金收款状态">
                <a-select allowClear placeholder="请选择收款状态" style="width: 100%" v-model="queryParam.cash_status">
                  <a-select-option v-for="op in cashStatusOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-affix :offsetTop="50" :style="{ left: '30px', width: '100%', 'text-align': 'left'}">
          <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
          <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
          <a-button type="danger" icon="delete" @click="handleDelete('all')" :disabled="!selectedRowKeys.length">删除</a-button>
          <a-button type="primary" @click="handleStore" :disabled="!selectedRowKeys.length">下发仓储</a-button>
          <a-button :disabled="!selectedRowKeys.length" @click="confirmCheck()">审核</a-button>
          <a-button type="primary" icon="export" @click="exportSheet">导出</a-button>
          <a-button type="primary" @click="startCashCharge">现金收费</a-button>
          <a-button type="danger" @click="outDateVisible = true">批量出库</a-button>
          <a-date-picker @change="onChangeDefaultDate" v-model="default_date"></a-date-picker>
        </a-affix>
      </div>

      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :filterColumn="true"
        :columns="columns"
        :data="loadData"
        :scroll="{ x: 'max-content' }"
        :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
        @dblclick="handleEdit"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="feeItem" slot-scope="text">
          <div v-for="item in text" :key="item.id">
            <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom: 2px">
              {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
            </a-tag>
            <span v-if="item.charge_type === 1">
              {{ item.fee_name }}
              <a-tag color="#A940FF" style="margin-bottom: 2px"> 现金：{{ item.amount }} </a-tag>
            </span>
            <span v-else-if="item.charge_type === 2">
              {{ item.fee_name }}
              <a-tag color="#DADADA" style="margin-bottom: 2px"> 月结：{{ item.amount }} </a-tag>
            </span>
          </div>
        </div>
        <div slot="cashRecordList" slot-scope="text">
          <span style="display: block" v-for="item in text" :key="item.id">
            <span v-if="item.cash_status === 0">
              <a-tag color="#1890ff" style="margin-bottom: 2px"> 待支付 </a-tag>
              {{ `金额:${item.amount}` }}
            </span>
            <span v-if="item.cash_status === 1">
              <a-tag color="#4cd964" style="margin-bottom: 2px"> 支付完成 </a-tag>
              {{ `金额:${item.amount} 交易号:${item.trx_id} 交易时间:${item.pay_date}` }}
            </span>
            <span v-if="item.cash_status === 2">
              <a-tag color="#faad14" style="margin-bottom: 2px"> 支付中 </a-tag>
              {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
            </span>
            <span v-if="item.cash_status === 3">
              <a-tag color="#ff4d4f" style="margin-bottom: 2px"> 支付失败 </a-tag>
              {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
            </span>
          </span>
        </div>
        <span slot="action" slot-scope="text, record, index">
          <template>
            <a @click="handleEdit(record, index)">编辑</a>
            <a-divider type="vertical" />
            <a @click="handleDelete('row', record)">删除</a>
            <a-divider type="vertical" />
            <a @click="openRecordTicket(record)">查看小票</a>
          </template>
        </span>
      </s-table>

      <a-modal
        :visible="ticketVisible"
        :confirmLoading="ticketLoading"
        :width="1520"
        :maskClosable="false"
        title="小票列表"
        @cancel="ticketVisible = false"
        :footer="null"
      >
        <a-table rowKey="id" :columns="ticketColumns" :data-source="ticketData" :pagination="false">
          <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
          <div slot="feeInfo" slot-scope="text">
            <span style="display: block" v-for="(item, index) in text" :key="index"
              >{{ item.fee_name }}:{{ item.amount }}</span
            >
          </div>
          <div slot="cashRecordList" slot-scope="text">
            <span style="display: block" v-for="item in text" :key="item.id">
              <span v-if="item.cash_status === 0">
                <a-tag color="#1890ff" style="margin-bottom: 2px"> 待支付 </a-tag>
                {{ `金额:${item.amount}` }}
                <a @click="delQRCode(item)">取消交易</a>
                <img :src="item.pay_info" style="width: 150px; height: 150px; border: 1px solid #f1f1f1" />
              </span>
              <span v-if="item.cash_status === 1">
                <a-tag color="#4cd964" style="margin-bottom: 2px"> 支付完成 </a-tag>
                {{ `金额:${item.amount} 交易号:${item.trx_id} 交易时间:${item.pay_date}` }}
              </span>
              <span v-if="item.cash_status === 2">
                <a-tag color="#faad14" style="margin-bottom: 2px"> 支付中 </a-tag>
                {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
              </span>
              <span v-if="item.cash_status === 3">
                <a-tag color="#ff4d4f" style="margin-bottom: 2px"> 支付失败 </a-tag>
                {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
              </span>
            </span>
          </div>
          <div slot="action" slot-scope="text, record">
            <a @click="printTicket(record)">打印</a>
            <a-divider type="vertical" />
            <a @click="delTicket(record)">删除</a>
          </div>
        </a-table>
      </a-modal>
      <a-modal
        :visible="printVisible"
        :width="420"
        :maskClosable="false"
        title="打印结费单"
        @cancel="printVisible = false"
      >
        <div id="printCmsBill">
          <CmsBill :data="printData"></CmsBill>
        </div>
        <template slot="footer">
          <a-button key="submit" type="primary" v-print="printCmsBill">确认打印</a-button>
        </template>
      </a-modal>

      <a-modal
        :visible="chargeTicketVisible"
        :confirmLoading="chargeTicketLoading"
        :width="1520"
        :maskClosable="false"
        title="收费小票列表"
        @cancel="chargeTicketVisible = false"
        :footer="null"
      >
        <a-table rowKey="id" :columns="chargeTicketColumns" :data-source="chargeTicketData" :pagination="false">
          <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
          <div slot="feeInfo" slot-scope="text">
            <span style="display: block" v-for="(item, index) in text" :key="index"
              >{{ item.fee_name }}:{{ item.amount }}</span
            >
          </div>
          <div slot="action" slot-scope="text, record">
            <a @click="delChargeTicket(record)">删除</a>
          </div>
        </a-table>
        <a-card style="margin-bottom: 10px">
          <a-radio-group name="radioGroup" v-model="dealType">
            <a-radio :value="1">微信</a-radio>
            <a-radio :value="2">支付宝</a-radio>
          </a-radio-group>
          <a-button type="primary" @click="createQRCode"> 创建收款码 </a-button>
          <div v-if="payInfo" class="charge-code">
            <img :src="payInfo" style="width: 200px; height: 200px; border: 1px solid #f1f1f1" />
          </div>
        </a-card>
      </a-modal>
      <a-modal
        :visible="outDateVisible"
        :confirmLoading="outDateLoading"
        :width="500"
        :maskClosable="false"
        title="批量出库"
        @cancel="outDateVisible = false"
        @ok="batchOutSubmit"
      >
        <a-date-picker v-model="outboundDate" format="YYYY-MM-DD" class="full-width"></a-date-picker>
      </a-modal>
    </a-card>
  </keep-alive>
</template>
<script>
import { STable } from '@/components'
import moment from 'moment'
import CmsBill from '@/views/wms/bill/CmsBill.vue'
import { getCommonOptions, downloadExcel } from '@/api/common'
import { filterOption } from '@/utils/util'
import {
  getCmsInfoPage,
  deleteCmsInfo,
  getCmsMetaOption,
  storeCmsInfo,
  confirmCmsInfo,
  getCmsTicketInfo,
  delCmsTicket,
  printCmsTicket,
  exportCheckInSheet,
  getCompany,
  getUnChargeTicket,
  getCmsInit,
  changeDefaultDate,
  bacthOutbound
} from '@/api/cms'
import { createWmsCashQRCode, delWmsCashDeal } from '@/api/wms'
import { getSysUserMetaOption } from '@/api/system'
import debounce from 'lodash/debounce'

export default {
  components: {
    STable,
    CmsBill
  },
  data() {
    this.lastFetchId = 0
    this.getCustomsCompany = debounce(this.getCustomsCompany, 800)
    return {
      default_date: null,
      queryParam: {},
      defaultDate: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center',
        },
        {
          title: '录单时间',
          dataIndex: 'receive_time',
          customRender: (item) => {
            if (item) {
              return moment(item).format('YYYY-MM-DD')
            } else {
              return ''
            }
          },
        },
        {
          title: '业务来源',
          dataIndex: 'source_name',
          sorter: true,
        },
        {
          title: '计划号',
          dataIndex: 'plan_id',
          sorter: true,
        },
        {
          title: '报关单号',
          dataIndex: 'report_num',
          sorter: true,
        },
        {
          title: '报关单位',
          dataIndex: 'customs_name',
          sorter: true,
        },
        {
          title: '品名',
          dataIndex: 'total_name',
          sorter: true,
        },
        {
          title: '下发状态',
          dataIndex: 'store_status',
          customRender: (text) => {
            return text === 1 ? '未下发' : text === 2 ? '已下发' : '已反馈'
          },
        },
        {
          title: '查验费用',
          dataIndex: 'cms_fees',
          scopedSlots: { customRender: 'feeItem' },
        },
        {
          title: '仓储费用',
          dataIndex: 'wms_fees',
          scopedSlots: { customRender: 'feeItem' },
        },
        {
          title: '合计',
          dataIndex: 'total',
        },
        {
          title: '现金收费',
          dataIndex: 'cash_record_list',
          scopedSlots: { customRender: 'cashRecordList' },
        },
        {
          title: '工号',
          dataIndex: 'worker_num',
          sorter: true,
        },
        {
          title: '入库日期',
          dataIndex: 'purchase_date',
          sorter: true,
          customRender: (item) => {
            if (item) {
              return moment(item).format('YYYY-MM-DD')
            } else {
              return ''
            }
          },
        },
        {
          title: '出库日期',
          dataIndex: 'release_date',
          sorter: true,
          customRender: (item) => {
            if (item) {
              return moment(item).format('YYYY-MM-DD')
            } else {
              return ''
            }
          },
        },
        {
          title: '货物状态',
          dataIndex: 'commodity_type_name',
          sorter: true,
        },
        {
          title: '箱型',
          dataIndex: 'box_type_name',
          sorter: true,
        },
        {
          title: '布控部门',
          dataIndex: 'control_department',
          sorter: true,
        },
        {
          title: '联系人',
          dataIndex: 'contact_name',
          sorter: true,
        },
        {
          title: '联系电话',
          dataIndex: 'contact_phone',
          sorter: true,
        },
        {
          title: '业务类型',
          dataIndex: 'business_name',
          sorter: true,
        },
        {
          title: '监管方式',
          dataIndex: 'supervise_name',
        },
        {
          title: '查验方式',
          dataIndex: 'inspect_type_name_str',
        },
        {
          title: '录单人',
          dataIndex: 'creator',
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      selectedRowKeys: [],
      currentData: null,
      sourceOps: [],
      commodityOps: [],
      bizTypeOps: [],
      boxOps: [],
      loadData: (parameter) => {
        return getCmsInfoPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res
        })
      },
      ticketColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '查验费用',
          dataIndex: 'fee_info_list',
          scopedSlots: { customRender: 'feeInfo' },
        },
        {
          title: '合计',
          dataIndex: 'total'
        },
        {
          title: '小票状态',
          dataIndex: 'ticket_status',
          customRender: (text) => {
            return text === 0 ? '未使用' : '已使用'
          }
        },
        {
          title: '交易信息',
          dataIndex: 'cash_record_list',
          scopedSlots: { customRender: 'cashRecordList' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        }
      ],
      ticketData: [],
      ticketVisible: false,
      ticketLoading: false,
      chargeTicketColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '计划号',
          dataIndex: 'order_num',
          align: 'center'
        },
        {
          title: '费用',
          dataIndex: 'fee_info_list',
          scopedSlots: { customRender: 'feeInfo' }
        },
        {
          title: '合计',
          dataIndex: 'total'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      chargeTicketData: [],
      chargeTicketVisible: false,
      chargeTicketLoading: false,
      printVisible: false,
      printCmsBill: {
        id: `printCmsBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`,
      },
      printData: [],
      userOps: [],
      feeStatusList: [
        { value: 1, name: `待审核` },
        { value: 2, name: `业务已审核` },
        { value: 3, name: `商务待审核` },
        { value: 4, name: `商务审核被驳回` },
        { value: 5, name: `待开票` },
        { value: 6, name: `已开票` }
      ],
      companyOps: [],
      fetching: false,
      customsCompanyInfo: undefined,
      storeStatusOps: [
        { value: 1, name: '未下发' },
        { value: 2, name: '已下发' },
        { value: 3, name: '已反馈' }
      ],
      cashStatusOps: [
        { value: 0, name: '待支付' },
        { value: 1, name: '支付完成' },
        { value: 2, name: '支付中' },
        { value: 3, name: '支付失败' }
      ],
      dealType: 1,
      payInfo: undefined,
      outDateVisible: false,
      outDateLoading: false,
      outboundDate: null
    }
  },
  created() {
    getCmsMetaOption('source').then((v) => {
      this.sourceOps = v
    })
    getCmsMetaOption('business_type').then((v) => {
      this.bizTypeOps = v
    })
    getCmsMetaOption('commodity_type').then((v) => {
      this.commodityOps = v
    })
    getCommonOptions('box_info').then((v) => {
      this.boxOps = v
    })
    getSysUserMetaOption('user').then((v) => {
      this.userOps = v
    })
    getCmsInit().then((v) => {
      this.default_date = v.checkin_default_date
      console.info(this.default_date)
    })
  },
  methods: {
    onChangeDefaultDate(date) {
      this.default_date = date.format('YYYY-MM-DD')
      changeDefaultDate({ appointDate: this.default_date }).then((res) => {
        if (res) {
          this.$message.success('设置默认日期成功')
        } else {
          this.$message.error('设置默认日期失败')
        }
      })
    },
    getCustomsCompany(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.companyOps = []
      this.fetching = true
      getCompany({ name: value }).then((res) => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.companyOps = res
        this.fetching = false
      })
    },
    changeCustomsCompanyInfo(value) {
      this.customsCompanyInfo = value
      if (value !== undefined) {
        this.queryParam['customs_company'] = value.key
      } else {
        this.queryParam['customs_company'] = null
      }
      this.companyOps = []
      this.fetching = false
    },
    exportSheet() {
      exportCheckInSheet(this.queryParam).then((res) => {
        if (res !== null) {
          const fileInfo = {
            fileName: res,
          }
          downloadExcel(fileInfo).then((downRes) => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    moment,
    filterOption,
    // 审核反审核查验订单
    confirmCheck() {
      confirmCmsInfo(this.selectedRowKeys).then((res) => {
        if (res) {
          this.$notification['success']({
            message: '提示',
            description: '审核成功',
          })
          this.$refs.table.clearSelected()
          this.printData = res
          this.printVisible = true
        }
        this.$refs.table.refresh(true)
      })
    },
    handleCreate() {
      this.$router.push({
        name: 'CheckInCUR',
        params: {
          id: 'create'
        }
      })
    },
    handleEdit(record) {
      this.currentData = record
      this.$router.push({
        name: 'CheckInCUR',
        params: record
      })
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteCmsInfo('check_in', { ids: _ids }).then((_) => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.',
            })
            this.$refs.table.refresh(true)
          })
        },
      })
    },
    handleStore() {
      this.$confirm({
        title: '确认提示',
        content: '确定要做此操作？',
        onOk: () => {
          storeCmsInfo({ ids: this.selectedRowKeys }).then((_) => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功.',
            })
            this.$refs.table.refresh(true)
          })
        },
      })
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['purchase_start_date'] = startDate
        this.queryParam['purchase_end_date'] = endDate
      } else {
        this.queryParam['purchase_start_date'] = null
        this.queryParam['purchase_end_date'] = null
      }
    },
    onDateChange2(date, dateString) {
      if (date[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['receive_start_date'] = startDate
        this.queryParam['receive_end_date'] = endDate
      } else {
        this.queryParam['receive_start_date'] = null
        this.queryParam['receive_end_date'] = null
      }
    },
    onDateChange3(date, dateString) {
      if (date[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['release_start_date'] = startDate
        this.queryParam['release_end_date'] = endDate
      } else {
        this.queryParam['release_start_date'] = null
        this.queryParam['release_end_date'] = null
      }
    },
    onDateChange4(date, dateString) {
      if (date[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['cash_start_date'] = startDate
        this.queryParam['cash_end_date'] = endDate
      } else {
        this.queryParam['cash_start_date'] = null
        this.queryParam['cash_end_date'] = null
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    openRecordTicket(record) {
      this.currentData = record
      this.ticketVisible = true
      this.getRecordTicket()
    },
    getRecordTicket() {
      getCmsTicketInfo({
        id: this.currentData.id,
      }).then((res) => {
        this.ticketData = res
      })
    },
    delTicket(record) {
      this.ticketLoading = true
      delCmsTicket({
        ticketId: record.id,
      })
        .then((res) => {
          if (res) {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.',
            })
            this.getRecordTicket()
            this.$refs.table.refresh(false)
          }
        })
        .finally((_) => {
          this.ticketLoading = false
        })
    },
    printTicket(record) {
      const ticketIdList = []
      ticketIdList.push(record.id)
      printCmsTicket(ticketIdList).then((res) => {
        this.printData = res
        this.printVisible = true
      })
    },
    startCashCharge() {
      if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
        this.payInfo = undefined
        this.dealType = 1
        this.getChargeTicket()
        this.chargeTicketVisible = true
      } else {
        this.$notification['warn']({
          message: '提示',
          description: '请选择要支付的订单.',
        })
      }
    },
    delChargeTicket(record) {
      if (this.payInfo) {
        return
      }
      for (let i = 0; i < this.chargeTicketData.length; i++) {
        if (this.chargeTicketData[i].id == record.id) {
          this.chargeTicketData.splice(i, 1)
        }
      }
    },
    getChargeTicket() {
      getUnChargeTicket(this.selectedRowKeys).then((res) => {
        if (res) {
          this.chargeTicketData = res
        }
      })
    },
    createQRCode() {
      createWmsCashQRCode({
        id_list: this.chargeTicketData.map((item) => item.id),
        deal_type: this.dealType,
      }).then((res) => {
        if (res) {
          this.payInfo = res
        }
      })
    },
    delQRCode(record) {
      delWmsCashDeal({
        cashId: record.id,
      }).then((res) => {
        if (res) {
          this.getRecordTicket()
        }
      })
    },
    batchOutSubmit() {
      this.outboundDate = this.outboundDate.format('YYYY-MM-DD')
      bacthOutbound({ 'ids': this.selectedRowKeys, 'out_date': this.outboundDate }).then((_) => {
        this.$notification['success']({
          message: '提示',
          description: '批量出库成功.'
        })
        this.outDateVisible = false
        this.outDateLoading = false
        this.outboundDate = null
        this.$refs.table.refresh(true)
      })
    }
  }
}
</script>

<style scoped>
.charge-code {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
</style>
